<template>
  <!-- Topbar Start -->
  <div class="container-fluid bg-dark px-0">
    <div class="row g-0 d-none d-lg-flex">
      <div class="col-lg-6 ps-5 text-start">
        <div class="h-100 d-inline-flex align-items-center text-light">
          <span>Follow Us:</span>
          <a class="btn btn-link text-light" href=""
            ><i class="fab fa-facebook-f"></i
          ></a>
          <a class="btn btn-link text-light" href=""
            ><i class="fab fa-twitter"></i
          ></a>
          <a class="btn btn-link text-light" href=""
            ><i class="fab fa-linkedin-in"></i
          ></a>
          <a class="btn btn-link text-light" href=""
            ><i class="fab fa-instagram"></i
          ></a>
        </div>
      </div>
      <div class="col-lg-6 text-end">
        <div
          class="h-100 bg-secondary d-inline-flex align-items-center text-dark py-2 px-4"
        >
          <span class="me-2 fw-semi-bold"
            ><i class="fa fa-phone-alt me-2"></i>Call Us:</span
          >
          <span>0789326245</span>
        </div>
      </div>
    </div>
  </div>
  <!-- Topbar End -->

  <!-- Navbar Start -->
  <nav
    class="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5"
  >
    <a href="index.html" class="navbar-brand d-flex align-items-center">
      <h1 class="m-0">Milky</h1>
    </a>
    <button
      type="button"
      class="navbar-toggler me-0"
      data-bs-toggle="collapse"
      data-bs-target="#navbarCollapse"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <div class="navbar-nav ms-auto p-4 p-lg-0">
        <a href="/" class="nav-item nav-link active">Home</a>
        <a href="/about" class="nav-item nav-link">About</a>
        <a href="/service" class="nav-item nav-link">Services</a>
        <a href="/product" class="nav-item nav-link">Products</a>
        <div class="nav-item dropdown">
          <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown"
            >Pages</a
          >
          <div class="dropdown-menu bg-light m-0">
            <a href="/gallery" class="dropdown-item">Gallery</a>
            <a href="/feature" class="dropdown-item">Features</a>
            <!-- <a href="team.html" class="dropdown-item">Our Team</a>
            <a href="testimonial.html" class="dropdown-item">Testimonial</a>
            <a href="404.html" class="dropdown-item">404 Page</a> -->
          </div>
        </div>
        <a href="/contact" class="nav-item nav-link">Contact</a>
      </div>
      <div class="border-start ps-4 d-none d-lg-block">
        <button type="button" class="btn btn-sm p-0">
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div>
  </nav>
  <!-- Navbar End -->

  <!-- Carousel Start -->
  <div class="container-fluid px-0 mb-5">
    <div id="header-carousel" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img class="w-100" src="img/carousel-1.jpg" alt="Image" />
          <div class="carousel-caption">
            <div class="container">
              <div class="row justify-content-start">
                <div class="col-lg-8 text-start">
                  <p class="fs-4 text-white">Welcome to our dairy farm</p>
                  <h1 class="display-1 text-white mb-5 animated slideInRight">
                    The Farm of Dairy products
                  </h1>
                  <a
                    href=""
                    class="btn btn-secondary rounded-pill py-3 px-5 animated slideInRight"
                    >Explore More</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <img class="w-100" src="img/carousel-2.jpg" alt="Image" />
          <div class="carousel-caption">
            <div class="container">
              <div class="row justify-content-end">
                <div class="col-lg-8 text-end">
                  <p class="fs-4 text-white">Welcome to our dairy farm</p>
                  <h1 class="display-1 text-white mb-5 animated slideInRight">
                    Best Organic Dairy Products
                  </h1>
                  <a
                    href=""
                    class="btn btn-secondary rounded-pill py-3 px-5 animated slideInLeft"
                    >Explore More</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#header-carousel"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#header-carousel"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
  <!-- Carousel End -->

  <!-- About Start -->
  <div class="container-xxl py-5">
    <div class="container">
      <div class="row g-5 align-items-end">
        <div class="col-lg-6">
          <div class="row g-2">
            <div
              class="col-6 position-relative wow fadeIn"
              data-wow-delay="0.7s"
            >
              <div class="about-experience bg-secondary rounded">
                <h1 class="display-1 mb-0">25</h1>
                <small class="fs-5 fw-bold">Years Experience</small>
              </div>
            </div>
            <div class="col-6 wow fadeIn" data-wow-delay="0.1s">
              <img class="img-fluid rounded" src="img/service-1.jpg" />
            </div>
            <div class="col-6 wow fadeIn" data-wow-delay="0.3s">
              <img class="img-fluid rounded" src="img/service-2.jpg" />
            </div>
            <div class="col-6 wow fadeIn" data-wow-delay="0.5s">
              <img class="img-fluid rounded" src="img/service-3.jpg" />
            </div>
          </div>
        </div>
        <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
          <p class="section-title bg-white text-start text-primary pe-3">
            About Us
          </p>
          <h1 class="mb-4">Know About Our Dairy Farm & Our History</h1>
          <p class="mb-4">
            Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu
            diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet
            lorem sit clita duo justo magna dolore erat amet
          </p>
          <div class="row g-5 pt-2 mb-5">
            <div class="col-sm-6">
              <img class="img-fluid mb-4" src="img/service.png" alt="" />
              <h5 class="mb-3">Dedicated Services</h5>
              <span
                >Clita erat ipsum et lorem et sit, sed stet lorem sit
                clita</span
              >
            </div>
            <div class="col-sm-6">
              <img class="img-fluid mb-4" src="img/product.png" alt="" />
              <h5 class="mb-3">Organic Products</h5>
              <span
                >Clita erat ipsum et lorem et sit, sed stet lorem sit
                clita</span
              >
            </div>
          </div>
          <a class="btn btn-secondary rounded-pill py-3 px-5" href=""
            >Explore More</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- About End -->

  <!-- Features Start -->
  <div class="container-xxl py-5">
    <div class="container">
      <div class="row g-5 align-items-center">
        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
          <p class="section-title bg-white text-start text-primary pe-3">
            Why Us!
          </p>
          <h1 class="mb-4">Few Reasons Why People Choosing Us!</h1>
          <p class="mb-4">
            Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu
            diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet
            lorem sit clita duo justo magna dolore erat amet
          </p>
          <p>
            <i class="fa fa-check text-primary me-3"></i>Justo magna erat amet
          </p>
          <p>
            <i class="fa fa-check text-primary me-3"></i>Aliqu diam amet diam et
            eos
          </p>
          <p>
            <i class="fa fa-check text-primary me-3"></i>Clita erat ipsum et
            lorem et sit
          </p>
          <a class="btn btn-secondary rounded-pill py-3 px-5 mt-3" href=""
            >Explore More</a
          >
        </div>
        <div class="col-lg-6">
          <div class="rounded overflow-hidden">
            <div class="row g-0">
              <div class="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                <div class="text-center bg-primary py-5 px-4">
                  <img class="img-fluid mb-4" src="img/experience.png" alt="" />
                  <h1 class="display-6 text-white" data-toggle="counter-up">
                    25
                  </h1>
                  <span class="fs-5 fw-semi-bold text-secondary"
                    >Years Experience</span
                  >
                </div>
              </div>
              <div class="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                <div class="text-center bg-secondary py-5 px-4">
                  <img class="img-fluid mb-4" src="img/award.png" alt="" />
                  <h1 class="display-6" data-toggle="counter-up">183</h1>
                  <span class="fs-5 fw-semi-bold text-primary"
                    >Award Winning</span
                  >
                </div>
              </div>
              <div class="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                <div class="text-center bg-secondary py-5 px-4">
                  <img class="img-fluid mb-4" src="img/animal.png" alt="" />
                  <h1 class="display-6" data-toggle="counter-up">2619</h1>
                  <span class="fs-5 fw-semi-bold text-primary"
                    >Total Animals</span
                  >
                </div>
              </div>
              <div class="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                <div class="text-center bg-primary py-5 px-4">
                  <img class="img-fluid mb-4" src="img/client.png" alt="" />
                  <h1 class="display-6 text-white" data-toggle="counter-up">
                    51940
                  </h1>
                  <span class="fs-5 fw-semi-bold text-secondary"
                    >Happy Clients</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Features End -->

  <!-- Banner Start -->
  <div
    class="container-fluid banner my-5 py-5"
    data-parallax="scroll"
    data-image-src="img/banner.jpg"
  >
    <div class="container py-5">
      <div class="row g-5">
        <div class="col-lg-6 wow fadeIn" data-wow-delay="0.3s">
          <div class="row g-4 align-items-center">
            <div class="col-sm-4">
              <img class="img-fluid rounded" src="img/banner-1.jpg" alt="" />
            </div>
            <div class="col-sm-8">
              <h2 class="text-white mb-3">We Sell Best Dairy Products</h2>
              <p class="text-white mb-4">
                Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo
                justo magna dolore erat amet
              </p>
              <a class="btn btn-secondary rounded-pill py-2 px-4" href=""
                >Read More</a
              >
            </div>
          </div>
        </div>
        <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
          <div class="row g-4 align-items-center">
            <div class="col-sm-4">
              <img class="img-fluid rounded" src="img/banner-2.jpg" alt="" />
            </div>
            <div class="col-sm-8">
              <h2 class="text-white mb-3">We Deliver Fresh Mild Worldwide</h2>
              <p class="text-white mb-4">
                Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo
                justo magna dolore erat amet
              </p>
              <a class="btn btn-secondary rounded-pill py-2 px-4" href=""
                >Read More</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Banner End -->

  <!-- Service Start -->
  <div class="container-xxl py-5">
    <div class="container">
      <div
        class="text-center mx-auto pb-4 wow fadeInUp"
        data-wow-delay="0.1s"
        style="max-width: 500px"
      >
        <p class="section-title bg-white text-center text-primary px-3">
          Our Services
        </p>
        <h1 class="mb-5">Services That We Offer For Entrepreneurs</h1>
      </div>
      <div class="row gy-5 gx-4">
        <div class="col-lg-4 col-md-6 pt-5 wow fadeInUp" data-wow-delay="0.1s">
          <div class="service-item d-flex h-100">
            <div class="service-img">
              <img class="img-fluid" src="img/service-1.jpg" alt="" />
            </div>
            <div class="service-text p-5 pt-0">
              <div class="service-icon">
                <img
                  class="img-fluid rounded-circle"
                  src="img/service-1.jpg"
                  alt=""
                />
              </div>
              <h6 class="mb-3">Best Animals Selection</h6>
              <p class="mb-4">
                Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem
                sed diam stet diam sed stet.
              </p>
              <a class="btn btn-square rounded-circle" href=""
                ><i class="bi bi-chevron-double-right"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 pt-5 wow fadeInUp" data-wow-delay="0.3s">
          <div class="service-item d-flex h-100">
            <div class="service-img">
              <img class="img-fluid" src="img/service-2.jpg" alt="" />
            </div>
            <div class="service-text p-5 pt-0">
              <div class="service-icon">
                <img
                  class="img-fluid rounded-circle"
                  src="img/service-2.jpg"
                  alt=""
                />
              </div>
              <h5 class="mb-3">Breeding & Veterinary</h5>
              <p class="mb-4">
                Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem
                sed diam stet diam sed stet.
              </p>
              <a class="btn btn-square rounded-circle" href=""
                ><i class="bi bi-chevron-double-right"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 pt-5 wow fadeInUp" data-wow-delay="0.5s">
          <div class="service-item d-flex h-100">
            <div class="service-img">
              <img class="img-fluid" src="img/service-3.jpg" alt="" />
            </div>
            <div class="service-text p-5 pt-0">
              <div class="service-icon">
                <img
                  class="img-fluid rounded-circle"
                  src="img/service-3.jpg"
                  alt=""
                />
              </div>
              <h5 class="mb-3">Care & Milking</h5>
              <p class="mb-4">
                Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem
                sed diam stet diam sed stet.
              </p>
              <a class="btn btn-square rounded-circle" href=""
                ><i class="bi bi-chevron-double-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Service End -->

  <!-- Gallery Start -->
  <div class="container-xxl py-5 px-0">
    <div class="row g-0">
      <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
        <div class="row g-0">
          <div class="col-12">
            <a class="d-block" href="img/gallery-5.jpg" data-lightbox="gallery">
              <img class="img-fluid" src="img/gallery-5.jpg" alt="" />
            </a>
          </div>
          <div class="col-12">
            <a class="d-block" href="img/gallery-1.jpg" data-lightbox="gallery">
              <img class="img-fluid" src="img/gallery-1.jpg" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
        <div class="row g-0">
          <div class="col-12">
            <a class="d-block" href="img/gallery-2.jpg" data-lightbox="gallery">
              <img class="img-fluid" src="img/gallery-2.jpg" alt="" />
            </a>
          </div>
          <div class="col-12">
            <a class="d-block" href="img/gallery-6.jpg" data-lightbox="gallery">
              <img class="img-fluid" src="img/gallery-6.jpg" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
        <div class="row g-0">
          <div class="col-12">
            <a class="d-block" href="img/gallery-7.jpg" data-lightbox="gallery">
              <img class="img-fluid" src="img/gallery-7.jpg" alt="" />
            </a>
          </div>
          <div class="col-12">
            <a class="d-block" href="img/gallery-3.jpg" data-lightbox="gallery">
              <img class="img-fluid" src="img/gallery-3.jpg" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
        <div class="row g-0">
          <div class="col-12">
            <a class="d-block" href="img/gallery-4.jpg" data-lightbox="gallery">
              <img class="img-fluid" src="img/gallery-4.jpg" alt="" />
            </a>
          </div>
          <div class="col-12">
            <a class="d-block" href="img/gallery-8.jpg" data-lightbox="gallery">
              <img class="img-fluid" src="img/gallery-8.jpg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Gallery End -->

  <!-- Product Start -->
  <div class="container-xxl py-5">
    <div class="container">
      <div
        class="text-center mx-auto wow fadeInUp"
        data-wow-delay="0.1s"
        style="max-width: 500px"
      >
        <p class="section-title bg-white text-center text-primary px-3">
          Our Products
        </p>
        <h1 class="mb-5">Our Dairy Products For Healthy Living</h1>
      </div>
      <div class="row gx-4">
        <div
          class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div class="product-item">
            <div class="position-relative">
              <img class="img-fluid" src="img/product-1.jpg" alt="" />
              <div class="product-overlay">
                <a
                  class="btn btn-square btn-secondary rounded-circle m-1"
                  href=""
                  ><i class="bi bi-link"></i
                ></a>
                <a
                  class="btn btn-square btn-secondary rounded-circle m-1"
                  href=""
                  ><i class="bi bi-cart"></i
                ></a>
              </div>
            </div>
            <div class="text-center p-4">
              <a class="d-block h5" href="">Pure Milk</a>
              <span class="text-primary me-1">$19.00</span>
              <span class="text-decoration-line-through">$29.00</span>
            </div>
          </div>
        </div>
        <div
          class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <div class="product-item">
            <div class="position-relative">
              <img class="img-fluid" src="img/product-2.jpg" alt="" />
              <div class="product-overlay">
                <a
                  class="btn btn-square btn-secondary rounded-circle m-1"
                  href=""
                  ><i class="bi bi-link"></i
                ></a>
                <a
                  class="btn btn-square btn-secondary rounded-circle m-1"
                  href=""
                  ><i class="bi bi-cart"></i
                ></a>
              </div>
            </div>
            <div class="text-center p-4">
              <a class="d-block h5" href="">Fresh Meat</a>
              <span class="text-primary me-1">$19.00</span>
              <span class="text-decoration-line-through">$29.00</span>
            </div>
          </div>
        </div>
        <div
          class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div class="product-item">
            <div class="position-relative">
              <img class="img-fluid" src="img/product-3.jpg" alt="" />
              <div class="product-overlay">
                <a
                  class="btn btn-square btn-secondary rounded-circle m-1"
                  href=""
                  ><i class="bi bi-link"></i
                ></a>
                <a
                  class="btn btn-square btn-secondary rounded-circle m-1"
                  href=""
                  ><i class="bi bi-cart"></i
                ></a>
              </div>
            </div>
            <div class="text-center p-4">
              <a class="d-block h5" href="">Dairy Products</a>
              <span class="text-primary me-1">$19.00</span>
              <span class="text-decoration-line-through">$29.00</span>
            </div>
          </div>
        </div>
        <div
          class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
          data-wow-delay="0.7s"
        >
          <div class="product-item">
            <div class="position-relative">
              <img class="img-fluid" src="img/product-4.jpg" alt="" />
              <div class="product-overlay">
                <a
                  class="btn btn-square btn-secondary rounded-circle m-1"
                  href=""
                  ><i class="bi bi-link"></i
                ></a>
                <a
                  class="btn btn-square btn-secondary rounded-circle m-1"
                  href=""
                  ><i class="bi bi-cart"></i
                ></a>
              </div>
            </div>
            <div class="text-center p-4">
              <a class="d-block h5" href="">Organic Food</a>
              <span class="text-primary me-1">$19.00</span>
              <span class="text-decoration-line-through">$29.00</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Product End -->

  <!-- Team Start -->
  <div class="container-xxl py-5">
    <div class="container">
      <div
        class="text-center mx-auto wow fadeInUp"
        data-wow-delay="0.1s"
        style="max-width: 500px"
      >
        <p class="section-title bg-white text-center text-primary px-3">
          Our Team
        </p>
        <h1 class="mb-5">Experienced Team Members</h1>
      </div>
      <div class="row g-4">
        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div class="team-item rounded p-4">
            <img class="img-fluid rounded mb-4" src="img/team-1.jpg" alt="" />
            <h5>Adam Crew</h5>
            <p class="text-primary">Founder</p>
            <div class="d-flex justify-content-center">
              <a
                class="btn btn-square btn-outline-secondary rounded-circle mx-1"
                href=""
                ><i class="fab fa-facebook-f"></i
              ></a>
              <a
                class="btn btn-square btn-outline-secondary rounded-circle mx-1"
                href=""
                ><i class="fab fa-twitter"></i
              ></a>
              <a
                class="btn btn-square btn-outline-secondary rounded-circle mx-1"
                href=""
                ><i class="fab fa-instagram"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div class="team-item rounded p-4">
            <img class="img-fluid rounded mb-4" src="img/team-2.jpg" alt="" />
            <h5>Doris Jordan</h5>
            <p class="text-primary">Veterinarian</p>
            <div class="d-flex justify-content-center">
              <a
                class="btn btn-square btn-outline-secondary rounded-circle mx-1"
                href=""
                ><i class="fab fa-facebook-f"></i
              ></a>
              <a
                class="btn btn-square btn-outline-secondary rounded-circle mx-1"
                href=""
                ><i class="fab fa-twitter"></i
              ></a>
              <a
                class="btn btn-square btn-outline-secondary rounded-circle mx-1"
                href=""
                ><i class="fab fa-instagram"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div class="team-item rounded p-4">
            <img class="img-fluid rounded mb-4" src="img/team-3.jpg" alt="" />
            <h5>Jack Dawson</h5>
            <p class="text-primary">Farmer</p>
            <div class="d-flex justify-content-center">
              <a
                class="btn btn-square btn-outline-secondary rounded-circle mx-1"
                href=""
                ><i class="fab fa-facebook-f"></i
              ></a>
              <a
                class="btn btn-square btn-outline-secondary rounded-circle mx-1"
                href=""
                ><i class="fab fa-twitter"></i
              ></a>
              <a
                class="btn btn-square btn-outline-secondary rounded-circle mx-1"
                href=""
                ><i class="fab fa-instagram"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Team End -->

  <!-- Testimonial Start -->
  <div class="container-xxl py-5">
    <div class="container">
      <div
        class="text-center mx-auto wow fadeInUp"
        data-wow-delay="0.1s"
        style="max-width: 500px"
      >
        <p class="section-title bg-white text-center text-primary px-3">
          Testimonial
        </p>
        <h1 class="mb-5">What People Say About Our Dairy Farm</h1>
      </div>
      <div class="row g-5 align-items-center">
        <div class="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
          <div class="testimonial-img">
            <img
              class="img-fluid animated pulse infinite"
              src="img/testimonial-1.jpg"
              alt=""
            />
            <img
              class="img-fluid animated pulse infinite"
              src="img/testimonial-2.jpg"
              alt=""
            />
            <img
              class="img-fluid animated pulse infinite"
              src="img/testimonial-3.jpg"
              alt=""
            />
            <img
              class="img-fluid animated pulse infinite"
              src="img/testimonial-4.jpg"
              alt=""
            />
          </div>
        </div>
        <div class="col-lg-7 wow fadeInUp" data-wow-delay="0.5s">
          <div class="owl-carousel testimonial-carousel">
            <div class="testimonial-item">
              <img class="img-fluid mb-3" src="img/testimonial-1.jpg" alt="" />
              <p class="fs-5">
                Dolores sed duo clita tempor justo dolor et stet lorem kasd
                labore dolore lorem ipsum. At lorem lorem magna ut et, nonumy et
                labore et tempor diam tempor erat.
              </p>
              <h5>Client Name</h5>
              <span class="text-primary">Profession</span>
            </div>
            <div class="testimonial-item">
              <img class="img-fluid mb-3" src="img/testimonial-2.jpg" alt="" />
              <p class="fs-5">
                Dolores sed duo clita tempor justo dolor et stet lorem kasd
                labore dolore lorem ipsum. At lorem lorem magna ut et, nonumy et
                labore et tempor diam tempor erat.
              </p>
              <h5>Client Name</h5>
              <span class="text-primary">Profession</span>
            </div>
            <div class="testimonial-item">
              <img class="img-fluid mb-3" src="img/testimonial-3.jpg" alt="" />
              <p class="fs-5">
                Dolores sed duo clita tempor justo dolor et stet lorem kasd
                labore dolore lorem ipsum. At lorem lorem magna ut et, nonumy et
                labore et tempor diam tempor erat.
              </p>
              <h5>Client Name</h5>
              <span class="text-primary">Profession</span>
            </div>
            <div class="testimonial-item">
              <img class="img-fluid mb-3" src="img/testimonial-4.jpg" alt="" />
              <p class="fs-5">
                Dolores sed duo clita tempor justo dolor et stet lorem kasd
                labore dolore lorem ipsum. At lorem lorem magna ut et, nonumy et
                labore et tempor diam tempor erat.
              </p>
              <h5>Client Name</h5>
              <span class="text-primary">Profession</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Testimonial End -->

  <!-- Footer Start -->
  <div
    class="container-fluid bg-dark footer mt-5 py-5 wow fadeIn"
    data-wow-delay="0.1s"
  >
    <div class="container py-5">
      <div class="row g-5">
        <div class="col-lg-3 col-md-6">
          <h5 class="text-white mb-4">Our Office</h5>
          <p class="mb-2">
            <i class="fa fa-map-marker-alt me-3"></i>123 Street, Kigali, RWANDA
          </p>
          <p class="mb-2"><i class="fa fa-phone-alt me-3"></i>0789326245</p>
          <p class="mb-2">
            <i class="fa fa-envelope me-3"></i>info@example.com
          </p>
          <div class="d-flex pt-3">
            <a class="btn btn-square btn-secondary rounded-circle me-2" href=""
              ><i class="fab fa-twitter"></i
            ></a>
            <a class="btn btn-square btn-secondary rounded-circle me-2" href=""
              ><i class="fab fa-facebook-f"></i
            ></a>
            <a class="btn btn-square btn-secondary rounded-circle me-2" href=""
              ><i class="fab fa-youtube"></i
            ></a>
            <a class="btn btn-square btn-secondary rounded-circle me-2" href=""
              ><i class="fab fa-linkedin-in"></i
            ></a>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <h5 class="text-white mb-4">Quick Links</h5>
          <a class="btn btn-link" href="">About Us</a>
          <a class="btn btn-link" href="">Contact Us</a>
          <a class="btn btn-link" href="">Our Services</a>
          <a class="btn btn-link" href="">Terms & Condition</a>
          <a class="btn btn-link" href="">Support</a>
        </div>
        <div class="col-lg-3 col-md-6">
          <h5 class="text-white mb-4">Business Hours</h5>
          <p class="mb-1">Monday - Friday</p>
          <h6 class="text-light">09:00 am - 07:00 pm</h6>
          <p class="mb-1">Saturday</p>
          <h6 class="text-light">09:00 am - 12:00 pm</h6>
          <p class="mb-1">Sunday</p>
          <h6 class="text-light">Closed</h6>
        </div>
        <div class="col-lg-3 col-md-6">
          <h5 class="text-white mb-4">Newsletter</h5>
          <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
          <div class="position-relative w-100">
            <input
              class="form-control bg-transparent w-100 py-3 ps-4 pe-5"
              type="text"
              placeholder="Your email"
            />
            <button
              type="button"
              class="btn btn-secondary py-2 position-absolute top-0 end-0 mt-2 me-2"
            >
              SignUp
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer End -->

  <!-- Copyright Start -->
  <div class="container-fluid bg-secondary text-body copyright py-4">
    <div class="container">
      <div class="row">
        <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
          &copy; <a class="fw-semi-bold" href="#">Your Site Name</a>, All Right
          Reserved.
        </div>
        <div class="col-md-6 text-center text-md-end">
          <!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***/-->
          Designed By
          <a class="fw-semi-bold" href="https://portfolio-ganza.pages.dev/"
            >Heritier Ganza Tamba</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- Copyright End -->
  <!-- Back to Top -->
  <a
    href="#"
    class="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"
    ><i class="bi bi-arrow-up"></i
  ></a>
</template>
